import { useRecoilCallback, useRecoilValue } from 'recoil'
import styled from 'styled-components'
import { getStorage } from '../../data/storage/storage'
import { getUserSetting, UserSettings } from '../../data/user/settings'
import { Session, SessionValue, SiteTheme } from '../../globals/state'
import { Language, languageIcons, languageNames } from '../../hooks/useLocalization'
import { FlexRow } from '../../styles/ui/layout'
import { getDropdownStyle, Select } from './select'

export const TinySelect = styled(Select)`
    width: 68px;
    flex-grow: 0 !important;
    flex-shrink: 1 !important;
`

type LanguageToggleProps = {
    shouldSave?: boolean
    transparentBg?: boolean
}

export function LanguageToggle({
    shouldSave = true,
    transparentBg = false,
}: LanguageToggleProps): JSX.Element {
    const settings = useRecoilValue(SessionValue('settings')) as UserSettings
    const theme = useRecoilValue(SiteTheme)
    const changeLanguage = useRecoilCallback(
        ({ set }) =>
            async (language: Language) => {
                set(Session, (session) => {
                    const newSession = { ...session, settings: { ...session.settings, uiLanguage: language } }
                    if (shouldSave) {
                        getStorage(newSession).saveSettings(newSession.settings)
                    }
                    return newSession
                })
            },
        []
    )
    return (
        <TinySelect
            menuPosition="fixed"
            isSearchable={false}
            aria-label="Select a language"
            menuPlacement="top"
            height={24}
            styles={{
                ...getDropdownStyle(theme),
                control: (provided) => ({
                    ...provided,
                    cursor: 'pointer',
                    ...(transparentBg && {
                        backgroundColor: 'transparent',
                        border: 'none',
                        borderWidth: '0',
                        outline: 'none',
                        boxShadow: 'none',
                        ':focus': {
                            outline: 'none',
                        },
                        ':focus-within': {
                            outline: 'none',
                        },
                    }),
                }),
                valueContainer: (provided) => ({
                    ...provided,
                    padding: '0px 8px 0 10px',
                    height: '24px',
                }),
                singleValue: (provided) => ({
                    ...provided,
                    lineHeight: '0',
                    height: '16px',
                    width: '16px',
                }),
                indicatorsContainer: (provided) => ({
                    ...provided,
                    padding: '0px 0px',
                    height: '22px',
                }),
            }}
            options={(Object.entries(languageIcons) as [string, string][]).map(([id, url]) => ({
                value: id,
                description: `${languageNames[id as Language]}`,
                label: (
                    <FlexRow>
                        <img src={url} width={16} height={16} alt={`${languageNames[id as Language]}`} />{' '}
                        <strong>{id.toUpperCase()}</strong>
                    </FlexRow>
                ),
            }))}
            onChange={(e: any) => {
                e && changeLanguage(e.value as Language)
            }}
            value={{
                value: getUserSetting(settings, 'uiLanguage'),
                description: `${languageNames[getUserSetting(settings, 'uiLanguage')]}`,
                label: (
                    <img
                        src={languageIcons[getUserSetting(settings, 'uiLanguage')]}
                        width={16}
                        height={16}
                        alt={languageNames[getUserSetting(settings, 'uiLanguage')]}
                    />
                ),
            }}
        />
    )
}
